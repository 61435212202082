import { onEffect } from "../core.state.commons"
import {
  APP_LOGOUT,
  APP_TENANT,
  APP_URL,
  SET_LOADER_STATUS,
  clearStorageData,
  getEnvionment,
  replaceTenant,
  runTimeoutForRefreshToken
} from "../forcepoint-platform-coreui"
import { ActionState } from "./core.state"
import * as singleSpa from "single-spa"
import { jwtDecode } from "jwt-decode"

export function CoreEffects() {
  /**
   * To fetch user info
   */
  onEffect(ActionState.FETCH_USER_INFO, (store) => {
    const storeData = store.getState()
    const userInfoUrl = APP_URL + "/platform/userinfo"
    const userToken = storeData.userToken
    fetch(userInfoUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          store.dispatch({ type: APP_LOGOUT })
          store.dispatch({ type: SET_LOADER_STATUS, status: false })
          window.location.href = `/platform/login?page=${(
            window.location.pathname + window.location.hash
          ).slice(1)}`
          return false
        } else {
          const userResponse = { ...res }
          const isEmulated = sessionStorage.getItem("isEmulated")
          if (userResponse.scope === "") {
            singleSpa.navigateToUrl("/401")
            store.dispatch({ type: SET_LOADER_STATUS, status: false })
          }
          const tenantId = userResponse?.ext?.tenantId?.find(
            (a) => a.hint.toLowerCase() === "sse"
          )?.id
          const gtenantId = userResponse?.ext?.tenantId?.find(
            (a) => a.hint.toLowerCase() === "global"
          )?.id
          if (
            (userResponse?.scope?.includes("eula.search") ||
              userResponse?.scope?.includes("eula:search")) &&
            gtenantId !== "00000000-0000-0000-0000-000000000000" &&
            isEmulated !== "true"
          ) {
            store.dispatch({
              type: ActionState.GET_PENDING_EULAS,
              payload: { userToken: userToken, userResponse }
            })
          } else if (
            gtenantId === "00000000-0000-0000-0000-000000000000" &&
            isEmulated !== "true"
          ) {
            store.dispatch({ type: ActionState.SET_USER_INFO, userResponse })
            store.dispatch({ type: ActionState.GET_APP_CONFIG })
          } else if (isEmulated === "true") {
            store.dispatch({ type: ActionState.SET_USER_INFO, userResponse })
            store.dispatch({ type: ActionState.GET_APP_CONFIG })
          } else {
            store.dispatch({ type: ActionState.SET_USER_INFO, userResponse })
            store.dispatch({ type: SET_LOADER_STATUS, status: false })
          }

          if (window.location.hostname !== "localhost") {
            window["walkme_data"].userId = gtenantId
            window["walkme_data"].companyId = tenantId
            window["walkme_data"].walkme_proxyURL = window.location.hostname
            window["walkme_data"].userScopes = userResponse?.scope
          }
          store.dispatch({
            type: ActionState.SET_APPLICATION_MESSAGES,
            language: "en-us"
          })
        }
      })
      .catch((err) => {
        store.dispatch({ type: APP_LOGOUT })
        store.dispatch({ type: SET_LOADER_STATUS, status: false })
        window.location.href = `/platform/login?page=${(
          window.location.pathname + window.location.hash
        ).slice(1)}`
      })
  })

  /**
   * Action to call app.json to get configuration
   */
  onEffect(ActionState.GET_APP_CONFIG, (store) => {
    const storeData = store.getState()
    const globalTenant = storeData?.userInfo?.ext?.tenantId.find(
      (a) => a?.hint === "global"
    )
    let appJSONUrl = APP_TENANT.includes("superadmin")
      ? "//localhost:9002/application/superadmin-app"
      : "//localhost:9002/application/app"
    if (window.location.hostname !== "localhost") {
      if (globalTenant.id === "00000000-0000-0000-0000-000000000000") {
        appJSONUrl = APP_URL + "/api/applications?applicationType=superAdmin"
      } else {
        appJSONUrl = APP_URL + "/api/applications"
      }
    }
    const userToken = storeData.userToken
    fetch(appJSONUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })
      .then((res) => res.json())
      .then((res) => {
        let appTenantVal: string = APP_TENANT.substr(0, APP_TENANT.length - 1)
        const domain = (APP_URL?.split(".").slice(-1))[0]
        if (!APP_URL.endsWith(".com")) {
          appTenantVal = `${APP_TENANT?.substr(
            0,
            APP_TENANT?.length - 1
          )}-${domain}`
        }
        for (const app of res) {
          if (app.modules !== undefined && app.modules !== null) {
            for (const module of app.modules) {
              module.moduleUrls.prefix = replaceTenant(
                module.moduleUrls.prefix,
                appTenantVal,
                domain
              )
              module.moduleUrls.apiURL = replaceTenant(
                module.moduleUrls.apiURL,
                appTenantVal,
                domain
              )
            }
          } else {
            app.modules = []
          }
          if (app.sharedModules !== undefined && app.sharedModules !== null) {
            for (const module of app.sharedModules) {
              module.moduleUrls.prefix = replaceTenant(
                module.moduleUrls.prefix,
                appTenantVal,
                domain
              )
              module.moduleUrls.apiURL = replaceTenant(
                module.moduleUrls.apiURL,
                appTenantVal,
                domain
              )
            }
          } else {
            app.sharedModules = []
          }
          if (app.applicationName === "Insights") {
            const tenantId = storeData.userInfo?.ext?.tenantId?.find(
              (a) => a.hint.toLowerCase() === "sse"
            )?.id
            if (tenantId === undefined) {
              const menus = app.menus?.filter(
                (a) =>
                  a.localizationKey !== "Summary" &&
                  a.localizationKey !== "Data Security" &&
                  a.localizationKey !== "Threat Protection"
              )
              app.menus = menus
            }
          }
          if (
            app.openInIframe &&
            app.iframeURL !== "" &&
            app.iframeURL !== null &&
            app.iframeURL !== undefined
          ) {
            app.iframeURL = app.iframeURL.replace("{tenantHost}", appTenantVal)
            app.iframeURL = app.iframeURL.replace(
              "OIDC_ACCESS_TOKEN",
              userToken
            )
            app.iframeURL = app.iframeURL.replace("ENV", getEnvionment())
          }
        }
        store.dispatch({
          type: ActionState.SET_APPLICATIONS,
          applications: res
        })
      })
      .catch((err) => {
        singleSpa.navigateToUrl("/401")
        store.dispatch({ type: SET_LOADER_STATUS, status: false })
      })
  })

  /**
   * To App logout
   */
  onEffect(ActionState.APP_LOGOUT, (store) => {
    const callLogoutUrl = true
    clearStorageData(callLogoutUrl)
    store.dispatch({ type: ActionState.SET_APP_LOGOUT_SUCCESS })
  })

  /**
   * To auto login user.
   */
  onEffect(ActionState.AUTO_LOGIN, (store) => {
    const cookies = document.cookie.split(";")
    const authCookie = cookies.find((a) =>
      a.includes(APP_TENANT + "cookie.oidc.auth")
    )

    const idCookie = cookies.find((a) =>
      a.includes(APP_TENANT + "cookie.oidc.id_token")
    )

    const [key, userTokenVal] = authCookie?.split("=") || []
    const [idKey, idTokenVal] = idCookie?.split("=") || []
    const decodedUserToken =
      userTokenVal !== null &&
      userTokenVal !== "null" &&
      userTokenVal !== "" &&
      userTokenVal !== undefined &&
      userTokenVal !== "undefined"
        ? JSON.parse(atob(userTokenVal))
        : null
    const decodedIdToken =
      idTokenVal !== null &&
      idTokenVal !== "null" &&
      idTokenVal !== "" &&
      idTokenVal !== undefined &&
      idTokenVal !== "undefined"
        ? jwtDecode(idTokenVal)
        : null
    const hostname = APP_URL?.split(".")?.splice(1)?.join(".") || null
    if (decodedIdToken !== null && decodedIdToken !== undefined) {
      document.cookie = `${APP_TENANT}cookie.oidc.id_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${hostname}`
      document.cookie = `${APP_TENANT}cookie.oidc.id_token=${idTokenVal}; Path=/; Domain=${""}`
      store.dispatch({
        type: ActionState.SET_ID_TOKEN,
        idToken: decodedIdToken
      })
    }

    if (
      decodedUserToken !== null &&
      decodedUserToken !== undefined &&
      decodedUserToken !== ""
    ) {
      document.cookie = `${APP_TENANT}cookie.oidc.auth=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${hostname}`
      const expiry = new Date(decodedUserToken.expiry).toUTCString()
      document.cookie = `${APP_TENANT}cookie.oidc.auth=${userTokenVal}; Path=/; Expires=${expiry}; Domain=${""}`
    }
    const cookieCheck = sessionStorage.getItem("cookieCheck")
    if (
      decodedUserToken !== null &&
      decodedUserToken !== undefined &&
      decodedUserToken !== "" &&
      cookieCheck !== undefined
    ) {
      const expiryDate = new Date(decodedUserToken.expiry).getTime()
      const currenTime = new Date().getTime()
      const dateInterval = expiryDate - currenTime
      window.parent.postMessage(
        {
          message: "NEW_TOKEN_RECEIVED",
          dateInterval: dateInterval,
          decodedUserToken: decodedUserToken.token,
          decodedIdToken: decodedIdToken
        },
        window.location.origin
      )
    }
    if (decodedUserToken !== null) {
      const expiryDate = new Date(decodedUserToken.expiry).getTime()
      const currenTime = new Date().getTime()
      const dateInterval = expiryDate - currenTime
      runTimeoutForRefreshToken(dateInterval)
      store.dispatch({
        type: ActionState.SET_USER_TOKEN,
        userToken: decodedUserToken.token
      })
      store.dispatch({ type: ActionState.FETCH_USER_INFO })
    } else {
      if (window.location.hostname !== "localhost") {
        if (window.location.pathname !== "/logout") {
          window.location.href = `/platform/login?page=${(
            window.location.pathname + window.location.hash
          ).slice(1)}`
        } else {
          store.dispatch({ type: SET_LOADER_STATUS, status: false })
        }
      } else {
        singleSpa.navigateToUrl("/local-login")
        store.dispatch({ type: SET_LOADER_STATUS, status: false })
      }
    }
  })

  /**
   * To set application messages
   */
  onEffect(ActionState.SET_APPLICATION_MESSAGES, (store, action) => {
    const JSONURL = "/assets/config/resources.en-us.json"
    fetch(JSONURL, {
      method: "GET"
    })
      .then((res) => res.json())
      .then((res) => {
        store.dispatch({
          type: ActionState.SET_APPLICATION_MESSAGES_SUCCESS,
          messages: res
        })
      })
  })

  /**
   * To get Pending EULAs
   */
  onEffect(ActionState.GET_PENDING_EULAS, (store, action) => {
    const url = APP_URL + "/api/eulas/pending"
    const { userToken, userResponse } = action.payload
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          store.dispatch({
            type: ActionState.SET_PENDING_EULAS,
            payload: { pendingEulas: res, token: userToken, userResponse }
          })
        } else if (res === null || res?.length === 0) {
          store.dispatch({
            type: ActionState.SET_PENDING_EULAS,
            payload: null
          })
          if (userResponse) {
            store.dispatch({ type: ActionState.SET_USER_INFO, userResponse })
            store.dispatch({ type: ActionState.GET_APP_CONFIG })
          }
        } else {
          singleSpa.navigateToUrl("/401")
          store.dispatch({ type: SET_LOADER_STATUS, status: false })
        }
      })
      .catch((err) => {
        singleSpa.navigateToUrl("/401")
        store.dispatch({ type: SET_LOADER_STATUS, status: false })
      })
  })
}
